<template>
  <div class="view view--home">
    <section class="section">
      <div class="container">
          <!-- <div class="col-12 col-md-6">
            <div class="wrapper wrapper--v-center">
              <h1 class="tit fw-500 fc-dark ts-40 ts-md-52 ta-center ta-md-left mb-2 mb-md-3" v-html="cc.s1.tit" />
              <p class="txt fw-400 fc-gray ts-18 ta-center ta-md-left mb-3 mb-md-5" v-html="cc.s1.txt" />
              <btn :data="cc.s1.btn" :theme="{type: 1, size: 'md', width: 174}" class="mx-auto mx-md-0"/>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <comp-img type="parallax1"/>
          </div> -->
            <slider></slider>
      </div>
      <a v-bind:class='$i18n.locale === "cs" ? "" : "d-none"' href="https://koza.coinfy.io" target="_blank">
        <div class="img-koza">
          <img src="/img/views/img-koza.png" alt="koza" />
        </div>
      </a>
    </section>
    <section class="section section--half">
      <comp-crypto comp="row" />
    </section>
    <section class="section section--snow">
      <div class="container">
        <div class="row mb-5 pb-lg-4">
          <div class="col-12">
            <p class="txt fw-400 fc-green ts-20 ts-md-22 ta-md-center mb-3" v-html="cc.s3.tit[0]" />
            <h2 class="tit fw-500 fc-dark ts-26 ts-md-32 ta-md-center" v-html="cc.s3.tit[1]" />
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-6 col-lg-3 mb-4 mb-sm-3 mb-lg-0 px-4 px-sm-2"
               v-for="(card, c) in cc.s3.cards" :key="`card-item-${c}`">
            <comp-card comp="t1" :data="card" />
          </div>
        </div>
      </div>
    </section>
    <section class="section section--snow">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <comp-crypto comp="tbl" />
          </div>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-8 col-xl-6">
            <h2 class="tit fw-500 fc-dark ts-26 ts-md-32 mb-4" v-html="cc.s5.tit" />
            <p class="txt fc-dark ts-16 ts-md-18" v-html="cc.s5.txt" />
          </div>
          <div class="col-12 col-lg-2 offset-lg-2 offset-xl-4 d-none d-md-block">
            <div class="wrapper wrapper--v-center">
              <btn :data="cc.s5.btn" :theme="{type: 1, size: 'md'}" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-4 mb-5 mb-md-0">
            <comp-img type="parallax2"/>
          </div>
          <div class="col-12 col-lg-8">
            <div class="row">
              <div class="col-12 col-sm-6 mb-4"
                   v-for="(card, c) in cc.s6.cards" :key="`card-item-${c}`">
                <comp-card comp="t2" :data="card" />
              </div>
            </div>
          </div>
        </div>
        <div class="row d-md-none">
          <div class="col-12 col-lg-2 offset-xl-4">
            <div class="wrapper wrapper--v-center">
              <btn :data="cc.s5.btn" :theme="{type: 1, size: 'md', width: 174}" class="mx-auto mx-md-0 ms-auto" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section">
      <comp-benefits class="mb-sm-3" />
      <comp-start class="mt-sm-3" />
    </section>
    <section class="section">
      <!--<comp-ad comp="a1" />-->
    </section>
  </div>
</template>
<script>
import Btn from '@/components/Btn'
import CompImg from '@/components/Img'
import CompCrypto from '@/components/Crypto'
import CompBenefits from '@/components/Benefits'
import CompStart from '@/components/Start'
import CompCard from '@/components/Card'
import Slider from '@/components/Slider'

export default {
  name: 'Home',
  components: {
    Btn, CompImg, CompCrypto, CompBenefits, CompStart, CompCard, Slider
  },
  computed: {
    cc () {
      return this.$t('views.home')
    }
  },
  metaInfo () {
    const meta = this.$t('meta.home')
    return {
      titleTemplate: '%s' + (meta.title ? ' | ' + meta.title : ''),
      meta: [
        { name: 'description', content: meta.description },
        { name: 'keywords', content: meta.keywords }
      ]
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  }
}
</script>
