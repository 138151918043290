<template>
  <!-- <swiper class="swiper" ref="swiper" :options="swiperOptions">
    <swiper-slide class="slide">
      <div class="row">
        <div class="col-12 col-md-6 mb-5">
          <div class="wrapper wrapper--v-center">
            <h1 class="tit fw-500 fc-dark ts-40 ts-md-52 ta-center ta-md-left mb-2 mb-md-3" v-html="cc.s1.tit" />
            <p class="txt fw-400 fc-gray ts-18 ta-center ta-md-left mb-3 mb-md-5" v-html="cc.s1.txt" />
            <btn :data="cc.btn" :theme="{type: 1, size: 'md', width: 174}" class="mx-auto mx-md-0 mb-md-5"/>
          </div>
        </div>
        <div class="col-12 col-md-6 mt-5 mt-md-0">
          <comp-img type="parallax1"/>
        </div>
      </div>
    </swiper-slide> -->
  <swiper class="swiper" ref="swiper" :options="swiperOptions">
    <swiper-slide class="slide" v-bind:class='$i18n.locale === "cs" ? "d-none" : ""'>
      <div class="row">
        <div class="col-12 col-md-6 mb-5">
          <div class="wrapper wrapper--v-center">
            <h1 class="tit fw-500 fc-dark ts-40 ts-md-52 ta-center ta-md-left mb-2 mb-md-3" v-html="cc.s4.tit" />
            <p class="txt fw-400 fc-gray ts-18 ta-center ta-md-left mb-3 mb-md-5" v-html="cc.s4.txt" />
            <btn-app platform="android" />
          </div>
        </div>
        <div class="col-12 col-md-6 mt-5 mt-md-0">
          <comp-img type="parallax8"/>
        </div>
      </div>
    </swiper-slide>
    <swiper-slide class="slide" v-bind:class='$i18n.locale === "cs" ? "" : "d-none"'>
      <div class="row">
        <div class="col-12 col-md-6 mb-5">
          <div class="wrapper wrapper--v-center">
            <h1 class="tit fw-500 fc-dark ts-40 ts-md-52 ta-center ta-md-left mb-2 mb-md-3" v-html="cc.s5.tit" />
            <p class="txt fw-400 fc-gray ts-18 ta-center ta-md-left mb-3" v-html="cc.s5.txt" />
            <p class="txt fw-400 fc-silver ts-14 ta-center ta-md-left mb-3 mb-md-5" v-html="cc.s5.txt2" />
            <btn :data="cc.s5.btn" :theme="{type: 1, size: 'md', width: 174}" class="mx-auto mx-md-0"/>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <comp-img type="parallax9"/>
        </div>
      </div>
    </swiper-slide>
    <!-- <swiper-slide class="slide" v-bind:class='$i18n.locale === "vi" ? "d-none" : ""'>
      <div class="row">
        <div class="col-12 col-md-6 mb-5">
          <div class="wrapper wrapper--v-center">
            <h1 class="tit fw-500 fc-dark ts-40 ts-md-52 ta-center ta-md-left mb-2 mb-md-3" v-html="cc.s3.tit" />
            <p class="txt fw-400 fc-gray ts-24 ta-center ta-md-left mb-3 mb-md-5" v-html="cc.s3.txt" />
            <btn :data="cc.btn" :theme="{type: 1, size: 'md', width: 174}" class="mx-auto mx-md-0"/>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <comp-img type="parallax7"/>
        </div>
      </div>
    </swiper-slide> -->
  </swiper>
</template>
<style lang="scss">
@media (min-width: 768px) {
  .swiper-container {
    height: 525px;
  }
  .swiper-slide {
    display: flex;
    align-items: center;
  }
}

</style>

<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import CompImg from '@/components/Img'
import btnApp from '@/components/BtnApp'
import Btn from '@/components/Btn'

export default {
  data: () => ({
    swiperOptions: {
      autoplay: {
        delay: 7000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true
      },
      speed: 600,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      }
    }
  }),
  components: {
    Swiper,
    SwiperSlide,
    CompImg,
    btnApp,
    Btn
  },
  directives: {
    swiper: directive
  },
  computed: {
    cc () {
      return this.$t('views.slider')
    },
    swiper () {
      return this.$refs.swiper.$swiper
    }
  },
  mounted () {
    console.log('Current Swiper instance object', this.swiper)
    // this.swiper.slideTo(3, 1000, false)
  }
}
</script>
