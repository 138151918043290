<template>
  <div class="container">
    <div class="row">
      <div class="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-0 px-0 pe-lg-2 mb-sm-3 mb-lg-0">
        <div class="benefits benefits--one">
          <div class="benefits__wrapper">
            <h3 class="tit fw-500 ts-26 ts-lg-32 mb-3" v-html="cc[0].tit" />
            <p class="txt fw-200 ts-16 ts-lg-18" v-html="cc[0].txt" />
          </div>
          <div class="benefits__wrapper">
            <ul class="list list--arrowed mb-3 mb-lg-0" v-for="(item, i) in list" :key="`parent-list${i}`">
              <li class="li" v-for="(li, l) in item" :key="`child-list-${i}-${l}`">
                <i class="ico ico--arrow ico--arrow-list ico-arrow-green">
                  <img src="/img/icons/ui/ico-arrow-list-green.svg" alt=">">
                </i>
                <span class="txt fc-white">{{ li }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-0 px-0 ps-lg-2">
        <div class="benefits benefits--two">
          <div class="mb-5 mb-md-0">
            <p class="txt fw-300 ts-20 ts-lg-22 benefits__pre" v-html="cc[1].tit[0]" />
            <h3 class="tit fw-500 ts-26 ts-lg-32 mb-2 mb-lg-3" v-html="cc[1].tit[1]" />
            <p class="txt fw-200 ts-16 ts-lg-18" v-html="cc[1].txt" />
          </div>
          <!-- <btn :data="cc[1].btn" :theme="{type: 4, size: 'md', width: 174}"/> -->
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
  @import "../assets/styles/components/Benefits";
</style>
<script>
// import Btn from '@/components/Btn'
export default {
  components: {
    // Btn
  },
  computed: {
    cc () {
      return this.$t('components.benefits')
    },
    list () {
      const items = this.cc[0].list
      const splitPoint = Math.round(items.length / 2)
      return [items.splice(0, splitPoint), items]
    }
  }
}
</script>
